











































































































































































































































































































































































































































































































.business-reviews{
  .rwg-app-example-slider{
    .rwg-title{
      display: none;
    }
    .rwg-review-header{
      display: none;
    }
  }
}
#MazPhoneNumberInput {
  label.input-tel__label, label.country-selector__label {
    color: #4b4b5a !important;
    font-weight: normal;
  }
}
